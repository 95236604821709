import React from 'react';
import '../../style/temp.css';
import Slideshow from '../slideShowImages';
import data from '../../data.json';
import all_images from '../../images';

function LivingRoom() {
  return (
    <>
        <div className="row g-0 design-heading">
            <div className="detailed-page-showcase-text">
                <h2>Living Room</h2>
                <p className="lead-text mb-0">
                    The living room is the heart of your home, where you gather with family and friends. Our living room designs combine minimalist principles with a royal touch, creating spaces that are open, inviting, and effortlessly elegant. We use a harmonious mix of textures and colours that are easy on the eyes, ensuring an ever green appeal. Our designs offer a natural look that is pleasing to the eye. Thoughtful arrangements and bespoke furniture pieces add a touch of sophistication to your living area.                
                </p>
            </div>
        </div>
        <div className="slideshow-main-container">
            <Slideshow images={all_images[data.ResidentialDesigns[0].images]}/>
        </div>
    </>
  );
}

export default LivingRoom;