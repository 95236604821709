import React from 'react';
import CustomHeader from './customHeader';

import OurServices from './ourServices';
import Testimonial from './testimonial';

function Home() {
  return (
    <section className='mt-5'>
        <CustomHeader />
        <OurServices /> 
    </section>
  );
}

export default Home;
