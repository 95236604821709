import React from 'react';
import '../../style/temp.css';
import { Carousel } from 'react-bootstrap';
import Slideshow from '../slideShowImages';
import data from '../../data.json';
import all_images from '../../images';


function Template() {

  return (
    <>
        <div className="design-heading">
                <span className="title">
                    <h1>Bedroom</h1>
                </span>
                <div className="project-details">
                    <span className="left"><h2>Area</h2> <h5>500 sq ft</h5></span>
                    <span className="right"><h2>Location</h2> <h5> Ghatkopar </h5></span>
                </div>
            <div className="detailed-page-showcase-text">  
                    <p className="lead mb-0">
                        At NeoAura Design Studio, we believe your bedroom should be a place of tranquillity and comfort. Our designs focus on creating elegant and peaceful environments with soothing colour palettes that promote relaxation and restful sleep. We incorporate timeless elements, ensuring your space remains stylish and inviting for years to come. Our designs blend modern aesthetics with cosy, homely vibes. From custom-built wardrobes to elegant lighting solutions, we tailor each detail to reflect your personal style and enhance your comfort.
                    </p>
            </div>
        </div>

        <div className="slideshow-main-container">
            <Slideshow images={all_images[data.ResidentialDesigns[0][0].images]}/>
        </div>

    </>
  );
}
export default Template;