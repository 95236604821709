import React from 'react';
import '../../style/temp.css';
import Slideshow from '../slideShowImages';
import data from '../../data.json';
import all_images from '../../images';


function Dining() {
  return (
    <>
        <div className="row g-0 design-heading">
            <div className="detailed-page-showcase-text">
                <h2>Dining Room</h2>
                <p className="lead-text mb-0">
                Create a dining space that fosters warmth and togetherness with our expertly designed dining rooms. Our designs are in sync with the living room, providing a very relaxing and communicative vibe. We focus on comfortable, stylish furniture and thoughtful layout designs that encourage conversation and enjoyment. Whether it's an intimate dining nook or a grand dining hall, our designs ensure your dining room is both aesthetically pleasing and functional, perfect for family meals and entertaining guests.
                </p>
            </div>
        </div>
        <div className="slideshow-main-container">
            <Slideshow images={all_images[data.ResidentialDesigns[1].images]}/>
        </div>
    </>
  );
}

export default Dining;