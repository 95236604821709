import React from 'react';
import '../../style/temp.css';
import Slideshow from '../slideShowImages';
import data from '../../data.json';
import all_images from '../../images';


function Kitchen() {
  return (
    <>
        <div className="row g-0 design-heading">
            <div className="detailed-page-showcase-text">
                <h2>Kitchen</h2>
                <p className="lead-text mb-0">
                    Our Kitchen designs prioritize functionality while maintaining a surreal and inviting atmosphere. We ensure ample natural light flows in through the windows, creating a bright and airy space. Our designs incorporate the latest trends in kitchen layout and storage solutions, making sure every element is both practical and visually pleasing. From custom cabinetry to innovative appliances, we tailor our designs to make your kitchen a delightful place to cook and gather.                
                </p>
            </div>
        </div>
        <div className="slideshow-main-container">
            <Slideshow images={all_images[data.ResidentialDesigns[4].images]}/>
        </div>
    </>
  );
}

export default Kitchen;