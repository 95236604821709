import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../style/navbar.css';
import logoWhiteBg from '../images/neoaura-white-bg-logo.jpg';
import { useLocation } from 'react-router-dom';

function CustomNavbar() {
  const [activeLink, setActiveLink] = useState(useLocation().pathname);

  return (
    <Navbar expand="lg" className="custom-navbar">
      <Container>
        <Navbar.Brand href="/">
          <img src={logoWhiteBg} className="navbar-logo" alt="NeoAura Logo" />
          <span className="navbar-brand-text">NeoAura</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link className={"mx-4 " + (activeLink === '/' ? 'active' : '')} href="/">Home</Nav.Link>
            <Nav.Link className={"mx-4 " + (activeLink === '/#services' ? 'active' : '')} href="/#services">Services</Nav.Link>
            <Nav.Link className={"mx-4 " + (activeLink === '/about-us' ? 'active' : '')} href="/about-us">About Us</Nav.Link>
            <Nav.Link className={"mx-4 " + (activeLink === '/work' || activeLink.startsWith('/work/') ? 'active' : '')} href="/work/residential">Work</Nav.Link>
            {/* <NavDropdown title="Work" id="basic-nav-dropdown" className={"mx-4 " + (activeLink === '/work' || activeLink.startsWith('/work/') ? 'active' : '')}>
              <NavDropdown.Item href="/work/residential">Residential</NavDropdown.Item>
              <NavDropdown.Item href="/work/commercial">Commercial</NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link className={"mx-4 " + (activeLink === '/faq' ? 'active' : '')} href="/faq">FAQs</Nav.Link>
            <Nav.Link className={"mx-4 " + (activeLink === '/contact-us' ? 'active' : '')} href="/contact-us">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
