import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import '../style/all-projects.css';
import Design from './design';
import data from '../data.json';
import all_images from '../images';

function AllProjects() {
  return (
    <section className='py-5 main-projects-section'>
      <div className="text-center mt-5">
          <h2 className="d-inline text-gradient">Our Work</h2>
      </div>
      <hr className="hr-work-title" />
        <section className="showcase my-5 mb-5">
            <div className="container-fluid p-0">
                {
                    data.ResidentialDesigns.map((design, index) => {
                        return <Design left={design.left} title={design.name} description={design.description} key={design.id} images={all_images[design.images]} link={design.link}/>
                    })
                }
            </div>
        </section>
        {/* <div></div> */}
    </ section>
  );
}

export default AllProjects;