import React from 'react';
import "../style/contact-us.css"

const ContactUs = () => {
  return (
    <section className="py-2 main-container">
      <div className="container-contact-us">
        <div className="bg-light rounded-4 px-4 py-md-5 py-sm-2 px-md-5">
          <div className="text-center mb-4">
            {/* <div className="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 mb-3">
              <i className="bi bi-envelope"></i>
            </div> */}
            <div className="text-center my-1">
                <h2 className="display-5 fw-bolder"><span className="text-gradient d-inline">Contact Us</span></h2>                  
            </div>
            <p className="lead fw-normal text-muted mb-0">Let's work together!</p>
          </div>
          <div className="row gx-5 justify-content-center">
            <div className="col-12 col-md-6 left-side">
              <p className="lead mb-0 text-center fw-normal text-muted">For any work related queries: 
                <br/> 
                <span className="contact-methods"><a>info@neoaura.in</a></span>
                <br/>
                <span className="contact-methods"><a>+91 9876543210</a></span>
                <br />
                <br />
                We are located at 
                <br />
                <span className="contact-methods">Address</span>
                <br />
                <br />
                We are open from 
                <br />
                <span className="contact-methods">Monday to Saturday 10AM - 7PM</span> 
              </p>
                       
            </div>
            <div className='col-12 col-md-6 right-side'>
              <p className="lead text-center fw-normal text-muted">We are hiring! <br/>Send out your resumes and portfolio on
                <br/> 
                <span className='contact-methods'><a>info@neoaura.in</a></span>
                {/* <br/>
                <br />
                Or drop a message on the WhatsApp number
                <br />
                <span className='contact-methods'><a>+91 9876543210</a></span> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
