import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import '../style/all-projects.css';

const Design = (props) => {
  
  const images = props.images.slice(0,1);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const showcaseImgRef = useRef(null);

  const updateBackgroundImage = (index) => {
    return { backgroundImage: `url(${images[index]})` };
  };

  const handleNextClick = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const handlePrevClick = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsVisible(entry.isIntersecting);
    }, {
      threshold: 1 // Adjust as needed
    });

    if (showcaseImgRef.current) {
      observer.observe(showcaseImgRef.current);
    }

    return () => {
      if (showcaseImgRef.current) {
        observer.unobserve(showcaseImgRef.current);
      }
    };
  }, []);

  return (
    <div className="row single-project g-0">
      <div
        id="showcase-img"
        ref={showcaseImgRef}
        className={`col-lg-6 text-white showcase-img ${props.left === true ? 'order-lg-2 left-side-image' : 'right-side-image'}`}
        style={updateBackgroundImage(currentImageIndex)}
      > 
      </div>
      <div className={`col-lg-6 showcase-text description-all-projects ${props.left === true ? 'order-lg-1' : 0}`}>
        <h2>{props.title}</h2>
        <p className="lead mb-0">
          {props.description}
        </p>
        <br/> 
        <Button href={props.link} className="btn-lg px-5 py-3 fs-6 fw-bolder" variant="outline-dark">View more</Button>
      </div>
    </div>
  );
};

export default Design;
