import React from 'react';
import '../style/our-service.css'


const OurServices = () => {
  return (
    <section className="bg-light page-section services" id="services">
      <div className="container px-4 px-lg-5">
        <div className="text-center mb-3">
          <h2 className="d-inline text-gradient">Our Services</h2>
        </div>
        <hr className="divider" />
        <div className="row  justify-content-center">
          <div className="col-xxl-8">
              <div className="text-center my-1">
                  <p className="lead fw-light mb-4">Discover our wide range of interior designing and architecture services, tailored to meet the specific needs of both residential and commercial clients. From elegant bedroom designs to lively office spaces, we ensure every project is a masterpiece.</p>
                  <p className="text-muted"></p>
              </div>
          </div>
        </div>
        <div className="row gx-4 gx-lg-5 services-div mt-4">
          <div className="col-12 col-md-3 text-center">
            <div className="mt-2">
              <div className="mb-2"><i className="bi-gem fs-1"></i></div>
              <h3 className="h4 mb-2">Interior Design Services</h3>
              <div className='services-list'>
                <ul>         
                    <li> Space Planning and Layout Design </li>
                    <li> Concept Development </li>
                    <li> Furniture and Fixture Selection </li>
                    <li> Material and Finish Selection </li>
                    <li> Custom Furniture Design </li>
                    <li> Interior Styling and Decoration </li>
                    <li> 3D Visualization and Rendering </li>
                    <li> Project Management </li>
                  </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 text-center">
            <div className="mt-2">
              <div className="mb-2"><i className="bi-gem fs-1"></i></div>
              <h3 className="h4 mb-2">Architectural Services</h3>
              <div className='services-list'>
                <ul>         
                  <li>	Architectural Design </li>
                  <li>	Site Analysis and Feasibility Studies </li>
                  <li>	Building Information Modeling (BIM) </li>
                  <li>	Construction Documentation </li>
                  <li>	Sustainable Design </li>
                  <li>	Renovation and Restoration </li>
                  <li>	Landscape Design </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 text-center">
            <div className="mt-2">
              <div className="mb-2"><i className="bi-gem fs-1"></i></div>
              <h3 className="h4 mb-2">Specialized Services</h3>
              <div className='services-list'>
                <ul>         
                  <li>	Interior Fit-Outs </li>
                  <li>	Branding and Identity Design </li>
                  <li>	Automation System Setu </li>
                  <li>	Acoustic Design &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</li>
                  <li>	Turnkey Projects </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 text-center">
            <div className="mt-2">
              <div className="mb-2"><i className="bi-gem fs-1"></i></div>
              <h3 className="h4 mb-2">Consulting Services</h3>
              <div className='services-list'>
                <ul>         
              
                  <li>	Design Consultation </li>
                  <li>	Budget Planning and Cost Estimation </li>
                  <li>	Basic and Detailed Vastu consultation </li>

                </ul>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>
  );
};

export default OurServices;
