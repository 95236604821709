import modern_nest_1 from '../src/images/ModernNest/1.jpg'
import modern_nest_2 from '../src/images/ModernNest/2.jpg'
import modern_nest_3 from '../src/images/ModernNest/3.jpg'
import modern_nest_4 from '../src/images/ModernNest/4.jpg'
import modern_nest_5 from '../src/images/ModernNest/5.jpg'
import modern_nest_6 from '../src/images/ModernNest/6.jpg'
import modern_nest_7 from '../src/images/ModernNest/BED1/1.jpg'
import modern_nest_8 from '../src/images/ModernNest/BED1/2a.jpg'
import modern_nest_9 from '../src/images/ModernNest/BED1/3.jpg'
import modern_nest_10 from '../src/images/ModernNest/BED1/4.jpg'
import modern_nest_11 from '../src/images/ModernNest/BED1/5.jpg'
import modern_nest_12 from '../src/images/ModernNest/BED1/6.jpg'
import modern_nest_13 from '../src/images/ModernNest/BED1/7.jpg'
import modern_nest_14 from '../src/images/ModernNest/BED1/8.jpg'
import modern_nest_15 from '../src/images/ModernNest/BED2/1a.jpg'
import modern_nest_16 from '../src/images/ModernNest/BED2/2.jpg'
import modern_nest_17 from '../src/images/ModernNest/BED2/3.jpg'
import modern_nest_18 from '../src/images/ModernNest/BED2/4.jpg'
import modern_nest_19 from '../src/images/ModernNest/BED3/1.jpg'
import modern_nest_20 from '../src/images/ModernNest/BED3/2.jpg'
import modern_nest_21 from '../src/images/ModernNest/BED3/3.jpg'
import modern_nest_22 from '../src/images/ModernNest/BED3/4.jpg'
import modern_nest_23 from '../src/images/ModernNest/BED3/5.jpg'
import modern_nest_24 from '../src/images/ModernNest/BED3/6.jpg'

import majestic_mansion_1 from '../src/images/MajesticMansion/1.jpg'
import majestic_mansion_2 from '../src/images/MajesticMansion/2.jpg'
import majestic_mansion_3 from '../src/images/MajesticMansion/3.jpg'
import majestic_mansion_4 from '../src/images/MajesticMansion/4.jpg'
import majestic_mansion_5 from '../src/images/MajesticMansion/5.jpg'
import majestic_mansion_6 from '../src/images/MajesticMansion/6.jpg'
import majestic_mansion_7 from '../src/images/MajesticMansion/BED1/1.jpg'
import majestic_mansion_8 from '../src/images/MajesticMansion/BED1/2.jpg'
import majestic_mansion_9 from '../src/images/MajesticMansion/BED1/3.jpg'
import majestic_mansion_10 from '../src/images/MajesticMansion/BED1/4.jpg'
import majestic_mansion_11 from '../src/images/MajesticMansion/BED2/1.jpg'
import majestic_mansion_12 from '../src/images/MajesticMansion/BED2/2.jpg'
import majestic_mansion_13 from '../src/images/MajesticMansion/BED2/3.jpg'
import majestic_mansion_14 from '../src/images/MajesticMansion/BED2/4.jpg'
import majestic_mansion_15 from '../src/images/MajesticMansion/BED2/5.jpg'
import majestic_mansion_16 from '../src/images/MajesticMansion/BED2/6.jpg'
import majestic_mansion_17 from '../src/images/MajesticMansion/BED2/7.jpg'
import majestic_mansion_18 from '../src/images/MajesticMansion/BED3/1.jpg'
import majestic_mansion_19 from '../src/images/MajesticMansion/BED3/2.jpg'
import majestic_mansion_20 from '../src/images/MajesticMansion/BED3/3.jpg'
import majestic_mansion_21 from '../src/images/MajesticMansion/BED3/4.jpg'
import majestic_mansion_22 from '../src/images/MajesticMansion/BED3/5.jpg'

import minimalist_elegance_1 from '../src/images/MinimalistElegance/1.jpg'
import minimalist_elegance_2 from '../src/images/MinimalistElegance/2.jpg'
import minimalist_elegance_3 from '../src/images/MinimalistElegance/3.jpg'
import minimalist_elegance_4 from '../src/images/MinimalistElegance/4.jpg'
import minimalist_elegance_5 from '../src/images/MinimalistElegance/5.jpg'
import minimalist_elegance_6 from '../src/images/MinimalistElegance/6.jpg'
import minimalist_elegance_7 from '../src/images/MinimalistElegance/7.jpg'
import minimalist_elegance_8 from '../src/images/MinimalistElegance/8.jpg'
import minimalist_elegance_9 from '../src/images/MinimalistElegance/BED1/1.jpg'
import minimalist_elegance_10 from '../src/images/MinimalistElegance/BED1/2.jpg'
import minimalist_elegance_11 from '../src/images/MinimalistElegance/BED1/3.jpg'
import minimalist_elegance_12 from '../src/images/MinimalistElegance/BED1/4.jpg'
import minimalist_elegance_13 from '../src/images/MinimalistElegance/BED1/5.jpg'
import minimalist_elegance_14 from '../src/images/MinimalistElegance/BED1/6.jpg'
import minimalist_elegance_15 from '../src/images/MinimalistElegance/BED2/1.jpg'
import minimalist_elegance_16 from '../src/images/MinimalistElegance/BED2/2.jpg'
import minimalist_elegance_17 from '../src/images/MinimalistElegance/BED3/1.jpg'
import minimalist_elegance_18 from '../src/images/MinimalistElegance/BED3/2.jpg'
import minimalist_elegance_19 from '../src/images/MinimalistElegance/BED3/3.jpg'
import minimalist_elegance_20 from '../src/images/MinimalistElegance/BED3/4.jpg'
import minimalist_elegance_21 from '../src/images/MinimalistElegance/BED4/1.jpg'
import minimalist_elegance_22 from '../src/images/MinimalistElegance/BED4/2.jpg'
import minimalist_elegance_23 from '../src/images/MinimalistElegance/BED4/3.jpg'
import minimalist_elegance_24 from '../src/images/MinimalistElegance/BED4/4.jpg'
import minimalist_elegance_25 from '../src/images/MinimalistElegance/BED5/1.jpeg'
import minimalist_elegance_26 from '../src/images/MinimalistElegance/BED5/2.jpeg'
import minimalist_elegance_27 from '../src/images/MinimalistElegance/BED5/3.jpeg'
import minimalist_elegance_28 from '../src/images/MinimalistElegance/BED5/4.jpeg'
import minimalist_elegance_29 from '../src/images/MinimalistElegance/BED5/5.jpg'
import minimalist_elegance_30 from '../src/images/MinimalistElegance/BED5/6.jpg'
import minimalist_elegance_31 from '../src/images/MinimalistElegance/BED6/1.jpg'
import minimalist_elegance_32 from '../src/images/MinimalistElegance/BED6/2.jpg'

import timeless_retreat_1 from '../src/images/TimelessRetreat/1.jpg'
import timeless_retreat_2 from '../src/images/TimelessRetreat/2.jpg'
import timeless_retreat_3 from '../src/images/TimelessRetreat/3.jpg'
import timeless_retreat_4 from '../src/images/TimelessRetreat/4.jpg'
import timeless_retreat_5 from '../src/images/TimelessRetreat/5.jpg'
import timeless_retreat_6 from '../src/images/TimelessRetreat/6.jpg'


const all_images = [
     // Majestic Mansion
     [
        majestic_mansion_1,
        majestic_mansion_2,
        majestic_mansion_3,
        majestic_mansion_4,
        majestic_mansion_5,
        majestic_mansion_6,
        majestic_mansion_7,
        majestic_mansion_8,
        majestic_mansion_9,
        majestic_mansion_10,
        majestic_mansion_11,
        majestic_mansion_12,
        majestic_mansion_13,
        majestic_mansion_14,
        majestic_mansion_15,
        majestic_mansion_16,
        majestic_mansion_17,
        majestic_mansion_18,
        majestic_mansion_19,
        majestic_mansion_20,
        majestic_mansion_21,
        majestic_mansion_22
    ],
    // Minimalist Elegance
    [
        minimalist_elegance_1,
        minimalist_elegance_2,
        minimalist_elegance_3,
        minimalist_elegance_4,
        minimalist_elegance_5,
        minimalist_elegance_6,
        minimalist_elegance_7,
        minimalist_elegance_8,
        minimalist_elegance_9,
        minimalist_elegance_10,
        minimalist_elegance_11,
        minimalist_elegance_12,
        minimalist_elegance_13,
        minimalist_elegance_14,
        minimalist_elegance_15,
        minimalist_elegance_16,
        minimalist_elegance_17,
        minimalist_elegance_18,
        minimalist_elegance_19,
        minimalist_elegance_20,
        minimalist_elegance_21,
        minimalist_elegance_22,
        minimalist_elegance_23,
        minimalist_elegance_24,
        minimalist_elegance_25,
        minimalist_elegance_26,
        minimalist_elegance_27,
        minimalist_elegance_28,
        minimalist_elegance_29,
        minimalist_elegance_30,
        minimalist_elegance_31,
        minimalist_elegance_32
    ],
    // Modern nest
    [
        modern_nest_1,
        modern_nest_2,
        modern_nest_3,
        modern_nest_4,
        modern_nest_5,
        modern_nest_6,
        modern_nest_7,
        modern_nest_8,
        modern_nest_9,
        modern_nest_10,
        modern_nest_11,
        modern_nest_12,
        modern_nest_13,
        modern_nest_14,
        modern_nest_15,
        modern_nest_16,
        modern_nest_17,
        modern_nest_18,
        modern_nest_19,
        modern_nest_20,
        modern_nest_21,
        modern_nest_22,
        modern_nest_23,
        modern_nest_24
    ],
    // Timeless Retreat
    [
        timeless_retreat_1,
        timeless_retreat_2,
        timeless_retreat_3,
        timeless_retreat_4,
        timeless_retreat_5,
        timeless_retreat_6
    ]
    
    
    
]



export default all_images