import React from 'react';
import '../style/footer.css';

const Footer = () => {
  return (
    <>
    {/* <hr className='footer-hr'></hr> */}
    <footer className="py-4">
      <div className="container px-5">
        <div className="row align-items-center justify-content-between flex-column flex-sm-row">
          {/* <div className="col-auto">
            <div className="small m-0">Copyright &copy; Your Website 2023</div>
          </div>
          <div className="col-auto">
            <a className="small" href="#!">Privacy</a>
            <span className="mx-1">&middot;</span>
            <a className="small" href="#!">Terms</a>
            <span className="mx-1">&middot;</span>
            <a className="small" href="#!">Contact</a>
          </div> */}
          <div className="d-flex justify-content-center fs-2 gap-4">
                <a className="social-media-bg" target='_blank'   href="https://www.instagram.com/neoaura.in/"><i className="bi bi-instagram"></i></a>
                <a className="social-media-bg" target='_blank'  href="https://wa.me/919819300050?text=Hey,%20I%20wanted%20a%20design%20for%20my%20project."><i className="bi bi-whatsapp"></i></a>
                <a className="social-media-bg" target='_blank' href=""><i className="bi bi-phone"></i></a>
            </div>
        </div>
      </div>
    </footer></>
  );
};

export default Footer;
