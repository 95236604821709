import React from 'react';
import '../style/about-me.css'; 
import profile_img from '../../src/images/profile-img-2.jpg'
import { LazyLoadImage } from "react-lazy-load-image-component";

function AboutMe() {
  return (
    <section className="py-3" id="about-us">
        <div className="container px-5">
            <div className="row gx-5 justify-content-center">
                <div className="col-xxl-8">
                    <div className="text-center my-5">
                        <div className="text-center mb-3">
                            <h2 className="d-inline text-gradient">About Us</h2>
                        </div>
                        <hr className="divider" />
                        <LazyLoadImage src={profile_img} className="img-fluid profile-image" alt="Large View" />
                        <p className="lead fw-light mb-4">
                                NeoAura Design Studio is an interior design and Architecture firm founded by Architect Chaitali Gangar, dedicated to creating beautiful and functional spaces. With a commitment to quality and an eye for detail, we bring your vision to life. We are passionate about crafting interiors that are not only visually stunning but also enhance the way you live and work.
                            <br/>
                            <br/>

                                Chaitali Mehta, a dedicated architect, completed her Bachelor's in Architecture from Thakur School of Architecture in 2019. With five years of professional experience, she has successfully completed over ten diverse projects, ranging from residential to commercial spaces. Her work is marked by a keen ability to blend creativity with functionality, ensuring that each design is both aesthetically pleasing and highly practical.
                            <br/>
                            <br/>
                                In 2023, Chaitali founded Neo Aura Design Studio, a firm where her passion for innovative design meets her commitment to client satisfaction. Neo Aura focuses on creating tailored spaces that resonate with both beauty and utility, embodying Chaitali's design philosophy of transforming spaces into unique experiences. Through Neo Aura, Chaitali continues to make a significant impact in the design industry, offering solutions that are both cutting-edge and deeply attuned to the needs of her clients.
                        </p>
                        <p className="text-muted"></p>
                    </div>

                    <div className="row mb-5">
                        <div className="col-6 mb-xl-0 mb-4">
                            <div className="card">
                                <div className="card-header p-3 pt-2">   
                                <div className="text-center pt-1">
                                    <h3><p className="mb-0 text-capitalize tag-heading"><i className="bi bi-mortarboard"></i></p></h3>
                                    <h5 className="mb-0">Experience</h5>
                                </div>
                                </div>
                                <hr className="dark horizontal my-0"/>
                                <div className="text-center py-3 card-header card-footer">
                                    <h3><p className="mb-0"><span className="text-success text-sm font-weight-bolder">4 years </span></p></h3>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-6 mb-xl-0 mb-4">
                            <div className="card">
                                <div className="card-header p-3 pt-2">   
                                <div className="text-center pt-1">
                                    <h3><p className="mb-0 text-capitalize tag-heading"><i className="bi bi-hammer"></i></p></h3>
                                    <h5 className="mb-0">Work in progress</h5>
                                </div>
                                </div>
                                <hr className="dark horizontal my-0"/>
                                <div className="text-center py-3 card-header card-footer">
                                    <h3><p className="mb-0"><span className="text-success text-sm font-weight-bolder">5000 sqft </span></p></h3>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default AboutMe;