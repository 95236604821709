import React from 'react';
import '../../style/temp.css';
import Slideshow from '../slideShowImages';
import data from '../../data.json';
import all_images from '../../images';


function Washroom() {
  return (
    <>
        <div className="row g-0 design-heading">
            <div className="detailed-page-showcase-text">
                <h2>Washroom</h2>
                <p className="lead-text mb-0">
                    Transform your washroom into a luxurious retreat with NeoAura’s modern and classy washroom designs. Our approach focuses on clean lines, uncluttered spaces, and soothing colour schemes that create a spa-like atmosphere. By integrating high-quality materials and contemporary design elements, we ensure your washroom is not only functional but also a place of relaxation and rejuvenation. We pay special attention to lighting and fixtures, creating a balance between practicality and elegance, making your daily routines a delightful experience.
                </p>
            </div>
        </div>
        <div className="slideshow-main-container">
            <Slideshow images={all_images[data.ResidentialDesigns[3].images]}/>
        </div>
    </>
  );
}

export default Washroom;