import {Routes,Route} from 'react-router-dom'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomNavbar from './components/customNavbar';
import Footer from './components/footer';
import Home from './components/home';
import AllProjects from './components/allProjects';
import Temp from './components/projects/Template';
import ContactUs from './components/contactUs';
import Faq from './components/faq';
import Loader from './components/loader';
import { useEffect, useState } from "react";
import Bedroom from './components/projects/Bedroom';

import Washroom from './components/projects/Washroom';
import Kitchen from './components/projects/Kitchen';
import Mandir from './components/projects/Mandir';
import Dining from './components/projects/Dining';
import Livingroom from './components/projects/LivingRoom';
import Template from './components/projects/Template';
import MajesticMansion from './components/projects/MajesticMansion';
import MinimalistElegance from './components/projects/MinimalistElegance';
import ModernNest from './components/projects/ModernNest';
import TimelessRetreat from './components/projects/TimelessRetreat';
import AboutMe from '../src/components/aboutMe';
import data from '../src/data.json'

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoading = () => {
    setIsLoading((prevState) => !prevState);
  };

  // useEffect(() => {
  //   window.addEventListener("load", handleLoading);
  //     return () => window.removeEventListener("load", handleLoading);
  //   }, []);

  return (
    <div className="App">
        <CustomNavbar/>

        <>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/about-us" element={<AboutMe/>} />
              <Route path="/work/residential" element={<AllProjects />} />
              <Route path="/work/residential/bedroom" element={<Bedroom />} />
              <Route path="/work/residential/living-room" element={<Livingroom />} />
              <Route path="/work/residential/washroom" element={<Washroom />} />
              <Route path="/work/residential/kitchen" element={<Kitchen />} />
              <Route path="/work/residential/majestic-mansion" element={<MajesticMansion />}/>
              <Route path="/work/residential/minimalist-elegance" element={<MinimalistElegance />} />
              <Route path="/work/residential/modern-nest" element={<ModernNest />} />
              <Route path="/work/residential/timeless-retreat" element={<TimelessRetreat />} />
              <Route path="/work/residential/dining-room" element={<Dining />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/temp" element={<Template />} />
            </Routes>
        
        </>
        <Footer />
      {/* </header> */}
    </div>
  );
}

export default App;
