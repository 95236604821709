import React, { useState } from 'react';
import '../style/slideshow.css'; // Ensure you have appropriate styles
import { Modal, Button } from 'react-bootstrap';
import { LazyLoadImage } from "react-lazy-load-image-component";

const Slideshow = (props) => {  
  const [slideIndex, setSlideIndex] = useState(1);
  const [thumbnailIndex, setThumbnailIndex] =  useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const plusSlides = (n) => {
    showSlides(n);
  };

  const currentSlide = (n) => {
   
    if(n<1){
      n=1;
    }
    setSlideIndex(n);
  };

  const showSlides = (n) => {
    let newIndex = slideIndex + n;
    if (newIndex > props.images.length) {
      newIndex = 1;
    } else if (newIndex <= 0) {
      newIndex = props.images.length;
    }
    setSlideIndex(newIndex);
    setThumbnailIndex(newIndex);
  };

  const getThumbnailsToDisplayBeforeActive = () => {
    
    let start = Math.max(slideIndex - 3, 0);
    let images = props.images.slice(start, slideIndex-1)
    
    return images;
  };

  const getThumbnailsToDisplayAfterActive = () => {
    const totalThumbnails = props.images.length;
    let end = Math.min(slideIndex + 2, totalThumbnails);
    let images = [];
    if(end >= totalThumbnails){
      images = props.images.slice(slideIndex);
    }
    images = props.images.slice(slideIndex, end);
    
    return images;
  };

  

  return (
    <div>
      <div className="slideshow-container">
        {
          props.images.map((imageSrc, index) => {
            return (
              <div className={`mySlides fade-img ${slideIndex === (index+1) ? 'active' : ''}`} style={{ display: slideIndex === (index+1) ? 'block' : 'none' }} key={(index+1)}>
                
                <LazyLoadImage src={imageSrc} className='slide-images' onClick={openModal} />
                <div className="prev-next">
                  <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
                  <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>
                </div>
                <div className="numbertext">{slideIndex} / {props.images.length}</div>
              </div>
            )
          })
        }
      </div>

      <br />
      <br/>

      <div className="thumbnail-slider">
        {slideIndex > 1 && (
          <button className="thumbnail-nav prev-thumbnails" onClick={() => currentSlide(slideIndex - 1)}>
            &#10094;
          </button>
        )}
        {getThumbnailsToDisplayBeforeActive().map((imageSrc, index) => (
          <img
            key={index + slideIndex}
            src={imageSrc}
            className="thumbnail"
            onClick={() => currentSlide(slideIndex - (2-index))}
            alt="Thumbnail"  
          />
          
        ))}
        <img
            key={slideIndex}
            src={props.images[slideIndex-1]}
            className="thumbnail-active"
            onClick={() => currentSlide(slideIndex)}
            alt={`Thumbnail ${slideIndex}`}
          />
        {getThumbnailsToDisplayAfterActive().map((imageSrc, index) => ( 
          <img
            key={index + slideIndex}
            src={imageSrc}
            className="thumbnail"
            onClick={() => currentSlide(index+slideIndex+1)}
            alt="Thumbnail"
          />
        ))}
        {slideIndex < props.images.length  && (
          <button className="thumbnail-nav next-thumbnails" onClick={() => currentSlide(slideIndex + 1)}>
            &#10095;
          </button>
        )}
      </div>

      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
            <div className="modal-content-wrapper">
              <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
              <img src={props.images[slideIndex-1]} className="img-fluid" alt="Large View" />
              <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>
            </div>  
        </Modal.Body>
        
      </Modal>
    </div>
  );
};

export default Slideshow;
