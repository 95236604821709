import React from 'react';
import { Button } from 'react-bootstrap';
import '../style/faq.css';

const Faq = () => {
  return (
    <section className="faq-section py-3 my-5">
      <div className="container mb-5 pt-5">
        <div className='row faq-top-container'>
          <div className="col-md-8 left-faq-col">
              <h2>Have a Question for us?</h2>
              <h4>Let's talk.</h4>
          </div>
          <div className="col-md-4 right-faq-col">
            <Button href="/contact-us" className="btn-lg px-5 py-3 fs-6 fw-bolder faq-btn custom-btn" >Contact Us</Button>
          </div>
        </div>
        <div className="w-lg-50 mt-md-5 mt-3 text-center">
          <h4 className="mb-3">FAQ | Frequently Asked Questions</h4>
          <div className="accordion " id="accordionExample">
            <div className="accordion-item">
              <h4 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#coll1" aria-expanded="true" aria-controls="coll1">
                  <span> What services does NeoAura Design Studio offer?</span>
                </button>
              </h4>
              <div id="coll1" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    At NeoAura Design Studio, we offer comprehensive interior design and Architecture Services tailored to meet your specific needs. Our services include conceptual design, space planning, furniture selection, material selection, project management, and more. Kindly contact us for more details                 </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#col12" aria-expanded="true" aria-controls="coll2">
                  <span> How does NeoAura Design Studio approach a new project?</span>
                </button>
              </h2>
              <div id="col12" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    We begin each project with a detailed consultation to understand your requirements, preferences, and budget. We then create a customized design proposal outlining the scope of work, timelines, and estimated costs. We believe in transparent communication and collaboration throughout the design process.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll3" aria-expanded="false" aria-controls="coll3">
                  <span> How does NeoAura Design Studio ensure quality and client satisfaction?</span>
                </button>
              </h2>
              <div id="coll3" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Quality and client satisfaction are at the core of everything we do. We work closely with our clients, associate architects, and contractors to ensure that every detail meets our high standards. Our goal is to exceed expectations and create spaces that inspire and delight.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll4" aria-expanded="false" aria-controls="coll4">
                  <span> Can NeoAura Design Studio work within my budget?</span>
                </button>
              </h2>
              <div id="coll4" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                We recognize that each project comes with its own budget limitations. While we aim to optimize value without sacrificing quality, our commitment remains steadfast: we prioritize maintaining the high standard of our work. We do not advocate for unrealistically low budgets that compromise the longevity and excellence of the final result.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll5" aria-expanded="false" aria-controls="coll5">
                  <span> How can I get started with NeoAura Design Studio? </span>
                </button>
              </h2>
              <div id="coll5" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    Getting started is easy. Simply contact us to schedule a consultation. During this initial meeting, we'll discuss your requirements, timeline, and budget. We'll then create a personalized design proposal to kick-start your project.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll6" aria-expanded="false" aria-controls="coll5">
                  <span> Does NeoAura Design Studio handle renovation projects? </span>
                </button>
              </h2>
              <div id="coll6" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    Yes, we specialize in both new construction and renovation projects. Whether you're looking to update your existing space or start from scratch, our team has the expertise to handle every aspect of the design process.                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll7" aria-expanded="false" aria-controls="coll5">
                  <span> What is NeoAura Design Studio's design style or approach? </span>
                </button>
              </h2>
              <div id="coll7" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Our design philosophy embraces a minimalistic approach that prioritizes functionality, comfort, and aesthetics. We aim to create spaces that reflect your personality and lifestyle.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll8" aria-expanded="false" aria-controls="coll5">
                  <span> Is NeoAura Design Studio available for projects outside of our local area? </span>
                </button>
              </h2>
              <div id="coll8" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    Yes, we are available for execution projects all over India and Designing projects all over the globe. We along with our experienced associates shall ensure a seamless design experience no matter where you're located.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll9" aria-expanded="false" aria-controls="coll9">
                  <span> How does NeoAura Design Studio handle project timelines? </span>
                </button>
              </h2>
              <div id="coll9" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    We understand the importance of adhering to project timelines. Our team carefully plans each phase of the project to ensure timely completion. We provide regular updates and maintain open communication with our clients to keep them informed every step of the way.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll10" aria-expanded="false" aria-controls="coll5">
                  <span> Does NeoAura Design Studio provide 3D visualizations or virtual reality walkthroughs? </span>
                </button>
              </h2>
              <div id="coll10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Yes, we offer 3D renderings and virtual reality walkthroughs as additional services to help you visualize your project before construction begins. This allows for better decision-making and ensures alignment with your vision.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll11" aria-expanded="false" aria-controls="coll5">
                  <span>Can NeoAura Design Studio assist with interior styling and accessorizing?</span>
                </button>
              </h2>
              <div id="coll11" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    Absolutely. In addition to core design services, we offer interior styling and accessorizing to add the finishing touches that elevate your space. From artwork selection to decorative accents, we curate elements that complement your design.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll12" aria-expanded="false" aria-controls="coll5">
                  <span>What sets NeoAura Design Studio apart from other interior designers?</span>
                </button>
              </h2>
              <div id="coll12" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                Our dedication to personalized service, meticulous attention to detail, and commitment to quality craftsmanship set us apart. We prioritize building long-term relationships with our clients based on trust, transparency, and exceptional design outcomes.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll13" aria-expanded="false" aria-controls="coll5">
                  <span>How does NeoAura Design Studio handle client feedback and revisions?</span>
                </button>
              </h2>
              <div id="coll13" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    We value client feedback throughout the design process and actively seek your input to refine and adjust designs as needed, ensuring the final result exceeds your expectations.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll14" aria-expanded="false" aria-controls="coll5">
                  <span>Does NeoAura Design Studio offer post-installation support or maintenance services?</span>
                </button>
              </h2>
              <div id="coll14" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    Yes, we provide post-installation support to address any queries or concerns after project completion. Additionally, we can assist with maintenance tips and recommendations to help preserve the integrity and beauty of your newly designed space.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#coll15" aria-expanded="false" aria-controls="coll5">
                  <span>How can I request a consultation with NeoAura Design Studio?</span>
                </button>
              </h2>
              <div id="coll15" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    To schedule a consultation, please contact us via phone, WhatsApp, or Email. We'll be happy to discuss your project goals, timeline, and budget, and provide guidance on how we can bring your vision to life.
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
