import React from 'react';
import '../../style/temp.css';
import Slideshow from '../slideShowImages';
import data from '../../data.json';
import all_images from '../../images';


function MinimalistElegance() {

  return (
    <>
        <div className="design-heading">
                <span className="title">
                    <h1>{data.ResidentialDesigns[1].name}</h1>
                </span>
                <div className="project-details">
                    <span className="left"><h2>Area</h2> <h5>{data.ResidentialDesigns[1].area}</h5></span>
                    <span className="right"><h2>Location</h2> <h5>{data.ResidentialDesigns[1].location}</h5></span>
                </div>
            <div className="detailed-page-showcase-text">  
                    <p className="lead mb-0">
                        {data.ResidentialDesigns[1].description}
                    </p>
            </div>
        </div>

        <div className="slideshow-main-container">
            <Slideshow images={all_images[data.ResidentialDesigns[1].images]}/>
        </div>

    </>
  );
}
export default MinimalistElegance;