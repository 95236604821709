import React from 'react';
import '../../style/temp.css';
import Slideshow from '../slideShowImages';
import data from '../../data.json';
import all_images from '../../images';


function Bedroom() {
  return (
    <>
        <div className="row g-0 design-heading">
            <div className="detailed-page-showcase-text">
                <h2>Bedroom</h2>
                <p className="lead-text mb-0">
                    At NeoAura Design Studio, we believe your bedroom should be a place of tranquillity and comfort. Our designs focus on creating elegant and peaceful environments with soothing colour palettes that promote relaxation and restful sleep. We incorporate timeless elements, ensuring your space remains stylish and inviting for years to come. Our designs blend modern aesthetics with cosy, homely vibes. From custom-built wardrobes to elegant lighting solutions, we tailor each detail to reflect your personal style and enhance your comfort.
                </p>
            </div>
        </div>
        <div className="slideshow-main-container">
            <Slideshow images={all_images[data.ResidentialDesigns[2].images]}/>
        </div>
    </>
  );
}

export default Bedroom;